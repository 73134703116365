import dayjs from "dayjs"
import Swal from "sweetalert2"
import { APIImg } from "../utils/API"
require("dayjs/locale/id")

//gobal function
export const isEmpty = (variable) => [null, "", undefined].includes(variable)
export const isHtml = (string = "") => {
	const htmlRegex = new RegExp(`<\\s*([^ >]+)[^>]*>.*?<\\s*/\\s*\\1\\s*>`)
	return htmlRegex.test(string)
}
export const formatCurrency = (text) => {
	if (text === "" || text === null || text === undefined) return "-"
	return new Intl.NumberFormat("ID", {
		style: "currency",
		currency: "IDR",
		minimumFractionDigits: 0,
		maximumFractionDigits: 0,
	}).format(text)
}

export const fixDate = (date = "", mark = "-") => {
	const [_date, time] = date.split(" ")
	const [day, month, year] = _date.split(mark)
	return `${year}${mark}${month}${mark}${day} ${time}`
}
export const formatDate = (date, format) => {
	if (date === "" || date === null || date === undefined) return "-"
	return dayjs(date).locale("id").format(format)
}

export const generateSearchTag = () => Date.now()
export const setMetaTag = (attr, key, content) => {
	if (isEmpty(content)) return
	let meta = document.querySelector(`meta[${attr}="${key}"]`)
	if (isEmpty(meta) && !meta) {
		meta = document.createElement("meta")
		meta.setAttribute(attr, key)
		document.head.appendChild(meta)
	}
	meta.setAttribute("content", content)
}

// image
export const readFile = (file) => {
	return new Promise((resolve, reject) => {
		const reader = new FileReader()
		reader.addEventListener("load", () => resolve(reader.result), false)
		reader.readAsDataURL(file)
	})
}

const createCropImgBase64 = (image, crop, round) => {
	const canvas = document.createElement("canvas")
	// const pixelRatio = window.devicePixelRatio;
	const pixelRatio = 1
	const scaleX = image.naturalWidth / image.width
	const scaleY = image.naturalHeight / image.height
	const ctx = canvas.getContext("2d")

	canvas.width = crop.width * pixelRatio * scaleX
	canvas.height = crop.height * pixelRatio * scaleY

	ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0)
	ctx.imageSmoothingQuality = "high"

	ctx.drawImage(image, crop.x * scaleX, crop.y * scaleY, crop.width * scaleX, crop.height * scaleY, 0, 0, crop.width * scaleX, crop.height * scaleY)

	if (round) {
		ctx.globalCompositeOperation = "destination-in"
		ctx.beginPath()
		ctx.arc(canvas.width / 2, canvas.height / 2, canvas.height / 2, 0, Math.PI * 2)

		ctx.closePath()
		ctx.fill()
	}

	return canvas.toDataURL("image/png")
}

export const getCrop = async (image, crop, round = false, width, height) => {
	const imgRatio = (image.height / image.width).toFixed(2)
	const crop_ratio = (height / width).toFixed(2)
	const crop_flag = round || imgRatio > crop_ratio
	var img_url = image.src
	if (crop_flag) {
		img_url = createCropImgBase64(image, crop, round)
	}
	let img = dataURLtoFile(img_url, "crop_img")
	return { img, img_url }
}

const dataURLtoFile = (dataurl, filename) => {
	var arr = dataurl.split(","),
		mime = arr[0].match(/:(.*?);/)[1],
		bstr = atob(arr[1]),
		n = bstr.length,
		u8arr = new Uint8Array(n)

	while (n--) {
		u8arr[n] = bstr.charCodeAt(n)
	}
	return new File([u8arr], filename, { type: mime })
}

export const moveImage = async (filename, folder) => {
	return APIImg.post(`image/move?name=${filename}&folder=${folder}`)
		.then((res) => res.data)
		.catch((err) => null)
}

export const getYoutubeDetail = async (link) => {
	return fetch(`https://noembed.com/embed?dataType=json&url=${link}`)
		.then((res) => res.json())
		.finally((data) => data)
}

export const nl2br = (str, is_xhtml) => {
	if (typeof str === "undefined" || str === null) {
		return ""
	}
	var breakTag = is_xhtml || typeof is_xhtml === "undefined" ? "<br />" : "<br>"
	return (str + "").replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, "$1" + breakTag + "$2")
}

// popup message
export const deleteAlert = async (text) => {
	return Swal.fire({
		icon: "warning",
		text: text,
		allowOutsideClick: false,
		cancelButtonText: "Batal",
		showCancelButton: true,
		confirmButtonText: "Ok",
		confirmButtonColor: "var(--main_color)",
		cancelButtonColor: "#d33",
	}).then((res) => res)
}

export const errorAlert = (text) => {
	return Swal.fire({ icon: "error", text })
}
