import React from 'react';
import style from '../../css/chat.module.css'
import CustomModal, { HeaderModal } from '../CustomComponent/CustomModal';
import { BASE_URL_IMG } from '../../utils/Constants';

function ChatImageView({ toggle = false, img = "", selleId, handleClose = () => { }, ...props }) {
    const title = "Gambar"
    const imgLink = `${BASE_URL_IMG}original/img/${selleId}/${img}`;

    return (
        <CustomModal outsideClick toggle={toggle} modalHandler={() => handleClose()} size="lg"
            modalProps={{ dialogClassName: style.img_popup }}
        >
            <HeaderModal title={title} modalHandler={() => handleClose()} />
            <div className={`scroll_container ${style.img_container}`}>
                <img src={imgLink} style={{ maxWidth: '100%' }} />
            </div>
        </CustomModal>
    );
}

export default ChatImageView;