import React from "react";
import { useLocation } from "react-router-dom";
import style from "../../css/mainCSS/footer.module.css";

export const FooterPart = (props) => {
  const { title = "", children } = props;
  return (
    <div className={style.part_container}>
      <div className={style.part_title}>{title}</div>
      {children}
    </div>
  );
};

function Footer(props) {
  const location = useLocation();
  const path = location.pathname.split("/")[1];
  const exception_page = [
    "login", "cart", "print", "register", "midtrans", "user_profile",
    "seller_dashboard", "create_store", "reset_password", "ecosystem_login"
  ];
  const exception_flag = exception_page.includes(path);

  // view
  if (exception_flag) return <></>;
  return (
    <div className={`body_part_container ${style.container}`}>
      <FooterPart title="Bantuan">
        <a href="">{`Cara Pemesanan`}</a>
        <a href="/terms">{`Syarat & Ketentuan`}</a>
        <a href="/privacy">{`Kebijakan Privasi`}</a>
      </FooterPart>
      <FooterPart title="Hubungi Kami">
        <a href="">
          <i className="bi bi-envelope" />
          {`beli@multipro.co.id`}
        </a>
        <a href="">
          <i className="bi bi-telephone-fill" />
          {`(CS) +62 877 75041 808`}
        </a>
        <a href="">
          <i className="bi bi-telephone-fill" />
          {`(021) 2942 9151`}
        </a>
      </FooterPart>
      <FooterPart title="Ikuti Kami">
        <a href={`https://www.youtube.com/@multiproid?app=desktop`} target="blank">
          <i className="bi bi-youtube" />
          {`multipro.id`}
        </a>
        <a href={`https://www.instagram.com/multipro.id`} target="blank">
          <i className="bi bi-instagram" />
          {`@multipro.id`}
        </a>
      </FooterPart>
    </div>
  );
}

export default Footer;
